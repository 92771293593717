import React from 'react';

// API
import { retrieveStudySessionStats } from 'api/stats'

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/useQuery';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Table } from 'components/core/table';
import { SelectDateInterval } from 'components/tools/select_date';
import { SlideOver } from 'components/core/slide_over';
import { StudentStatsExtendedViewQueryBased} from 'components/students/stats';
import { SelectTabs } from 'components/core/select';
import { EmptyState } from 'components/core/empty';
import { Paginate } from 'components/core/paginate';
import { Error } from 'components/core/typo';
import { Popover } from 'components/tools/popover';
import { FiSettings } from 'react-icons/fi';
import { CheckBox } from 'components/tools/checkbox';
import { Link } from 'react-router-dom';

// Utils
import { locale, dateParse } from 'utils/locale';
import classnames from 'classnames'
import { format } from 'd3-format';
import { timeDay } from 'd3-time';

// Contexts
import { SearchStudySessionsStatsContext, SearchStudySessionsStatsContextProvider } from 'contexts/follow_up_stats'

const POSITIVE_THRESHOLD = 0.75;

function StudentCell({student}){
  return <div className="whitespace-nowrap w-fit">
    <Link to={`/students/${student.ni}`}  target='_blank' className="hover:underline " onClick={(e)=> e.stopPropagation()}>
      {student.name}
    </Link>
  </div>
  
}
function OverCell({results}){
  const numOver = useMemo(()=>{
    if (!results) return;
    return results.reduce((prev, next)=>{
      return prev + Math.max(0,next.num_study_sessions_done - next.num_study_sessions_target)
    }, 0)
  }, [])
  return <div className="flex items-center justify-center">
          <p className={classnames("rounded-md text-center mx-auto px-2 py-0", numOver>0? "bg-blue-500 text-white font-medium": "text-gray-500")}>{numOver}</p>
        </div>

}
export function SubjectCell({num_study_sessions_done, num_study_sessions_target, num_exclusions, displayCount}){
  const {t} = useTranslation('common');
  const color = useMemo(()=>{
    const t = parseFloat(Math.min(3, num_study_sessions_target));
    const d = parseFloat(Math.min(3, num_study_sessions_done));
    if (t===0){
      if (d===0){
        return "bg-green-200 text-green-800";
      }
      else {
        return "bg-blue-200 text-blue-800";
      }
    }
    else {
      const f = d/t;

      if (f>=POSITIVE_THRESHOLD){
        return "bg-green-100 text-green-800";
      }
      if (d==0){
        return 'bg-red-200 text-red-800'
      }
      return "bg-yellow-200 text-yellow-800"
    }
  }, [num_study_sessions_target, num_study_sessions_done])
  return <div className="flex items-center justify-center space-x-4 py-0.5 font-medium">
          {num_exclusions?
          <p className={classnames("h-full py-1 px-2 text-center whitespace-nowrap bg-gray-500 text-white rounded")}>
              E {num_study_sessions_done} / {Math.min(3,num_study_sessions_target)}
        </p>:
          displayCount?
            <p className={classnames("h-full py-1 px-2 text-center whitespace-nowrap bg-gray-100 rounded", color)}>
              {num_study_sessions_done} / {Math.min(3,num_study_sessions_target)}
            </p>:
            <p className={classnames("h-full py-1 px-2 text-center whitespace-nowrap bg-gray-100 rounded", color)}>
            {num_study_sessions_target==0? "100%":
              num_study_sessions_target?
              format(".0%")(parseFloat(num_study_sessions_done)/(parseFloat(Math.min(num_study_sessions_target,3)))): t("over")}
            </p>
          }
          </div>
}

function StudySessionStatsTable({setOpen, setQuery, displayCount, stats}){
  const {t} = useTranslation('common');
  const headers = useMemo(()=>{
    var h = [
      {
          title: t("students"),
          field: (d=>d? d: "student"),
          itemClassName: "py-0",
          FormatComponent: StudentCell
      }
    ];
    if (stats && stats.length>0){
      stats[0].results.forEach((e,i)=>{
        const header = {
          title: locale.format("%d %b")(dateParse(e.date)),
          field: (d=>d?d .results[i]: `date-${i}`),
          headerId: locale.format("%d %b")(dateParse(e.date)),
          format: (d=><SubjectCell displayCount={displayCount} {...d}/>),
          className: "text-center whitespace-nowrap"
        }
        h.push(header)
      })
    }
    // Over
    h.push({
      title: t("over-ss"),
      field: (d=>d?d: "over-ss"),
      FormatComponent: OverCell,
      className: "text-center whitespace-nowrap"

    })

    h.push({
          title: t("ratio"),
          field: (d=>d? d.ratio: "ratio"),
          className: "text-center ",
          itemClassName:(d=>d<POSITIVE_THRESHOLD? "text-center  text-red-500 bg-red-50": "text-center text-green-500 bg-green-50"),
          format: format(".0%")
      })

    return h;
  }, [stats, displayCount]);

  const onRowClick = useCallback((d)=>{
    setOpen(true); setQuery({student: d.student.ni});
  }, []);

  return <Table headers={headers}
                data={stats}
                onRowClick={onRowClick}
                rowClassName={"hover:bg-gray-200"}
                indexingKey={(d=>`${d.student.ni}`)} />
}

export function StudySessionStatsWithContext({preset, team, achieveTarget, Context=SearchStudySessionsStatsContext}){
  const {t} = useTranslation('common');
  const {filters} = useContext(Context);
  const [showLeftServiceStudents, setShowLeftServiceStudents] = useState(false);
  const [dates, setDates] = useState({fromDate: timeDay.offset(new Date(), -30), toDate: new Date()});
  const params = useMemo(()=>({preset,
                          team,
                          stepDays:7,
                          ...filters,
                          showLeftServiceStudents,
                          achieveTarget,
                          fromDate: dates.fromDate || timeDay.offset(new Date(), -30),
                          toDate: dates.toDate || new Date()
                        }),
      [preset, team, filters, showLeftServiceStudents, dates]);

  const validateParams = useCallback(({fromDate, toDate})=> fromDate && toDate, [])
  const [stats, {error, paging, setPage}] = useSearch(retrieveStudySessionStats, params, {validateParams, limit:500});

  const {query, setQuery} = useQuery();
  const [open, setOpen] = useState(query.has("student"));
  const [displayCount, setDisplayCount] = useState(true);

  useEffect(()=>{
    if (!open){setQuery()}
  }, [open])

  const tabs = useMemo(()=>[
      {name: "Abs", value: true},
      {name: "%", value: false},
    ], [])
  return <>
          <div className='py-3 flex flex-wrap items-start justify-between  bg-white'>

            <div>
              {achieveTarget && <p className="mr-3 text-lg">{t("students-to-check")}</p>}
              <SelectDateInterval missingToDateIsNow={true}
                orientation="left"
                canRemoveSelection={false}
                value={dates}
                setValue={setDates} />
            </div>

            <div  className="flex items-center justify-between -mt-1 space-x-3">
              
              
              <div className='flex items-center space-x-3'>
                <Popover color="default" label={<FiSettings className='w-5 h-5 text-gray-600'/>}>
                  <CheckBox 
                    name={t("show-left-service-students.title")}
                    description={showLeftServiceStudents? t("show-left-service-students.positive"): t("show-left-service-students.negative")}
                    value={showLeftServiceStudents} 
                    setValue={setShowLeftServiceStudents}/>
                </Popover>
                <SelectTabs values={tabs} setValue={(v)=>setDisplayCount(v)}/>
              </div>
            </div>
          </div>
          {
            stats?
            stats.length===0?
            <EmptyState
                title={t(!achieveTarget?"empty-state.no-students-on-watching-list-terrain-title": "empty-state.no-students-title")}
                description={t(!achieveTarget?"empty-state.no-students-on-watching-list-terrain-description": "empty-state.no-students-description")}/>:
                
                <div className="h-full max-h-[calc(100vh-300px)] overflow-scroll">
                   <StudySessionStatsTable
                    setOpen={setOpen}
                    displayCount={displayCount}
                    setQuery={setQuery}
                    stats={stats}/>
                  </div>:
                  <Skeleton className="w-full h-80"/>
          }
          {paging&& <Paginate setPage={setPage} {...paging}/>}
            {error? <Error.Text className="mt-3" {...error}/>: null}
          <SlideOver open={open} setOpen={setOpen} size="xl3">
            <StudentStatsExtendedViewQueryBased dates={dates}/>
           </SlideOver>
         </>
}

export function StudySessionStats({storageKey="followup-stats", ...props}={}){
  return <SearchStudySessionsStatsContextProvider storageKey={storageKey}>
          <StudySessionStatsWithContext {...props}/>
    </SearchStudySessionsStatsContextProvider>
}


