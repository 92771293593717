import React, {createContext, useCallback, useContext } from "react";

// Hooks
// import { useLocation } from 'react-router-dom';
// import { useMemoCompare } from 'hooks/useMemoCompare';
import { useMemo } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContext } from 'contexts/search';

// API
import { searchTutors } from 'api/tutors';

const TutorsContext = createContext({});

const TutorsContextProvider = ({ children, jsSortBy, augment, fields, limit }) => {
  const { filters } = useContext(SearchContext);
  // const location = useLocation();
  const [tutors, {loading, paging, hasMore, setPage, error, setResult, execute}] = useSearch(searchTutors, 
                                                                                              filters, 
                                                                                              {limit: limit || 200, 
                                                                                                fields: ["name", "ni", "retired_of_service_since", ...(fields || [])]});
  const isLoaded = useMemo(()=>!!tutors, [tutors]);
  // const paramsChanged = useMemoCompare(location, (prev, next)=>prev && prev.pathname==next.pathname);
  // useEffect(()=>{
  //   execute()
  // }, [paramsChanged])

  const sortedTutors = useMemo(()=>{
    if (!jsSortBy) return tutors;
    return tutors && tutors.sort(jsSortBy);
  }, [tutors, jsSortBy]);

  const augmentedTutors = useMemo(()=>{
    if (!augment) return sortedTutors;
    return sortedTutors && sortedTutors.map(augment);
  }, [sortedTutors, augment]);

  const updateTutor= useCallback((tutor) => {
    setResult((tutors) => tutors.map((t) => t.ni === tutor.ni ? tutor: t));
  }, [setResult]);

  return (
    <TutorsContext.Provider value={{ tutors, sortedTutors, augmentedTutors, setTutors: setResult, reload:execute, loading, paging, setPage, error, hasMore, isLoaded, updateTutor }}>
      {children}
    </TutorsContext.Provider>
  );
};

export { TutorsContext, TutorsContextProvider };