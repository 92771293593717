export const content =
`
<code>Staging</code>

# Journal des modifications

## 28 mars 2025

À la fermeture des tuteurs, les antécédents judiciaires sont remis à zéro si la date de validation date de plus de 3 ans. À la réinscription du tuteur, il devra refournir un antécédent judiciaire valide.

Dans le recrutement, les colonnes "Appel 2" et "Appel 3" sont ajoutées. 

## 21 mars 2025

La vue de recrutement est restaurée.

## 12 mars 2025

Les exclusions aux aides aux devoirs sont prises en compte dans le calcul du temps de jeu, autant les exclusions par école ou individuelles. Le temps de jeu transmis tient compte de ces exclusions. Si toute la semaine est exclue, le total sera gris avec 1/1 et Temps de jeu complet.

![Exclusion aides aux devoirs](/assets/changelog/exclusion_pt.png)

## 3 mars 2025

### Ajout de 2FA par application d'authentification

La double authentification par application d'authentification est maintenant disponible. Vous pouvez activer cette fonctionnalité dans les paramètres de sécurité votre compte. Si active, le 2FA par application sera préféré au 2FA par SMS.

![2FA par application](/assets/changelog/2fa-update.png)

## 25 février 2025

### Améliorations de sécurité

Plusieurs améliorations de sécurité ont été apportées à l'application. La plupart des améliorations sont invisibles pour les utilisateurs, mais elles permettent de protéger les données des utilisateurs et de garantir la confidentialité des informations.

## 21 février 2025

### Aucun jumelage automatique en ligne durant le midi

Les séances en ligne sont uniquement faites après l'école entre 15h45 et 20h. Le jumelage automatique prend cette contrainte en compte et ne propose pas de séances en ligne durant le midi.

### Disponibilités au portail

Un rafrachissement de la page des paramètres des tuteurs a été effectué. Les disponibilités des tuteurs sont maintenant affichées groupées par jour de la semaine. Une liste de suggestions de disponibilités est également affichée pour chaque école. 

Il n'est plus possible de choisir une plage horaire le vendredi soir. 

Un badge "En personne" apparaît pour les disponibilités le midi.

![Disponibilités au portail](/assets/changelog/dispo-portail.png)


### Brouillons de notes

L'éditeur sauvegarde automatiquement la progression de la note. Vous pouvez donc quitter la page et revenir plus tard pour terminer la note.

> **La note est sauvegardée sur votre appareil et n'est pas synchronisée avec les autres appareils.** Ainsi, si vous perdez la connexion ou si vous changez d'appareil, vous ne pourrez pas retrouver votre note en cours de rédaction.

Dans la barre de navigation en haut à droite de l'écran, un nouvel onglet a été ajouté pour consulter les brouillons de notes. Un brouillon est automatiquement supprimé lorsqu'il est publié.

![Brouillons de notes](/assets/changelog/top-navbar.png)

### Avertissement pas d'Internet
L'application vous avertie lorsque votre connexion Internet est rompue et lorsqu'elle est de retour.
 
![Avertissement pas d'internet](/assets/changelog/no-internet.png)

### Avertissements à l'importation d'élèves-athlètes

À l'importation des élèves-athlètes, les rangées du tableau résumé sont maintenant colorés en blanc, jaune ou rouge se la gravité de l'erreur. 

Une liste des erreurs critiques est inscrite et empêche l'importation.
Une liste des avertissements a été ajoutée. Il est nécessaire de confirmer ces avertissements pour importer les élèves-athlètes.

![Avertissemtsn](/assets/changelog/avertissement-import.png)
`
