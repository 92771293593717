import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { ModifiableSelectField, SingleLineField } from 'components/tools/modifiable_fields';
import { CheckBox } from 'components/tools/checkbox';
import { SelectWeeklyAvailabilities } from 'components/tools/select_weekly_availabilities';
import { SelectPreferences, SelectSubjectPreferencesAuto, SelectSchoolPreferencesAuto  } from 'components/tools/select_preferences';
import { Info } from 'components/core/info';
import { InfoSectionHeading } from 'components/core/typo';

// Utils
import classnames from 'classnames';

// Contexts
import { TutorContext } from 'contexts/tutor';

// Hooks
import { useContext, useCallback } from 'react';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useAPI } from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';

// API
import { updateTutor, 
          retrieveSubjectPreferences, 
        updateSubjectPreferences, 
        retrieveSchoolPreferences, 
        updateSchoolPreferences} from 'api/tutors';
import { SelectConfidence } from 'components/tools/select_confidence';


const subjectPreferenceOptions = {
  "none": {name:"Pas du tout", previous: null, next: "low", qte:0},
  "low": {name:"Partiellement", previous: "none", next: "high", qte:1},
  "high": {name:"Très", previous: "low", next: null, qte:2},
}


export function SelectDraftSubjectPreferences({value, setValue}){
  const { t } = useTranslation('common');
  const handleChange = useCallback(({name, slug, description})=>{
    setValue(value.map(d=>d.slug!==slug? d: ({name, slug, description})))
  }, [value]);

  return  <Info.Container modify={true} className="my-8">
         <InfoSectionHeading mediumTitle={t('subject-preferences')} />
         <SelectSubjectPreferencesAuto 
        values={value  || []} 
        setValues={setValue}
        setValue={handleChange} 
        formatValue={(d)=>d.name} 
        options={subjectPreferenceOptions}
        defaultOption={subjectPreferenceOptions.none}/>
        </Info.Container>
}




function SelectSubjectPreferences({ni}){
  const { tutor, setTutor } = useContext(TutorContext);
  const { t } = useTranslation('common');
  const params = useMemoCompare({ni}, (prev, next)=>prev && prev.ni===next.ni)
  const [value, {setResult}]= useAPI(retrieveSubjectPreferences, params)
  const handleChange = useCallback(({subject, description})=>{
    updateSubjectPreferences({ni, subject, description});
    setResult({...value, preferences:value.preferences.map(d=>d.subject.slug!==subject.slug? d: ({...d, description}))})
  }, [value])

  return  <Info.Container modify={true} className="my-8">
    <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("accept-triade.label")} 
                      subLabel={t("accept-triade.subLabel")} 
                      modify={true}
                      marker="select"
                      value={tutor.accept_triade || false}
                      setValue={
                        (acceptTriade)=>{
                          updateTutor({ni, acceptTriade});
                          setTutor(d=>({...d, accept_triade: acceptTriade}))
                        }
                      }/>
           {tutor?.online_preference && tutor?.accept_triade && <div className='bg-yellow-100 text-yellow-600 px-3 py-2 rounded-md'>
                <p className='text-sm'>{t("accept-triade.online-preference")}</p>
            </div>}
         <InfoSectionHeading mediumTitle={t('subject-preferences')} />
         <SelectPreferences 
        values={value && value.preferences} 
        setValue={handleChange} 
        formatValue={(d)=>d.subject.name} 
        options={subjectPreferenceOptions}
        defaultOption={subjectPreferenceOptions.none}/>
        <div className="pt-6">
        <SingleLineField
          label={t("comment-on-prefered-subjects")}
          modify={true}
          value={tutor.comment_on_prefered_subjects}
          rows={4}
          multiLine={true}
        setValue={(commentOnPreferedSubjects) => { updateTutor({ ni, commentOnPreferedSubjects }); 
        setTutor(d => ({ ...d, comment_on_prefered_subjects:commentOnPreferedSubjects })) }}>
        </SingleLineField>

        <div className='mt-12 space-y-3'>
          <ModifiableSelectField
            noBorder={true}
            Field={SelectConfidence}
            label={t("student-with-troubles")}
            modify={true}
            disabledClear
            value={tutor.student_with_troubles} 
            setValue={(studentWithTroubles) => { updateTutor({ ni, studentWithTroubles }); 
            setTutor(d => ({ ...d, student_with_troubles:studentWithTroubles })) }}/>
          <SingleLineField
            label={t("precision-on-student-with-troubles")}
            modify={true}
            value={tutor.precision_on_student_with_troubles}
            rows={4}
            multiLine={true}
          setValue={(precisionOnStudentWithTroubles) => { updateTutor({ ni, precisionOnStudentWithTroubles }); 
          setTutor(d => ({ ...d, precision_on_student_with_troubles:precisionOnStudentWithTroubles })) }}>
          </SingleLineField>
        </div>

        <div className='mt-12 space-y-3'>
          <ModifiableSelectField
            noBorder={true}
            Field={SelectConfidence}
            label={t("student-in-frencization-class")}
            modify={true}
            disabledClear
            value={tutor.student_in_frencization_class} 
            setValue={(studentInFrencizationClass) => { updateTutor({ ni, studentInFrencizationClass }); 
            setTutor(d => ({ ...d, student_in_frencization_class:studentInFrencizationClass })) }}/>
          <SingleLineField
            label={t("precision-on-student-in-frencization")}
            modify={true}
            value={tutor.precision_on_student_in_frencization}
            rows={4}
            multiLine={true}
          setValue={(precisionOnStudentInFrencization) => { updateTutor({ ni, precisionOnStudentInFrencization }); 
          setTutor(d => ({ ...d, precision_on_student_in_frencization:precisionOnStudentInFrencization })) }}>
          </SingleLineField>
        </div>
      </div>
      
        </Info.Container>

}

const schoolPreferenceOptions = {
  "none": {name:"Non disponible", previous: null, next: "dispo", qte:0},
  "dispo": {name:"Disponible", previous: "none", next: "prioritaire", qte:1},
  "prioritaire": {name:"Prioritaire", previous: "dispo", next: null, qte:2},
}

export function SelectDraftSchoolPreferences({value, setValue}){
  const { t } = useTranslation('common');
  const handleChange = useCallback(({name, slug, description})=>{
    setValue(value.map(d=>d.slug!==slug? d: ({name, slug, description})))
  }, [value]);

  return  <Info.Container modify={true} className="my-8">
         <InfoSectionHeading mediumTitle={t('school-preferences')} />
         <SelectSchoolPreferencesAuto 
        values={value  || []} 
        setValues={setValue}
        setValue={handleChange} 
        formatValue={(d)=>d.name} 
        options={schoolPreferenceOptions}
        defaultOption={schoolPreferenceOptions.none}/>
        </Info.Container>
}
function SelectSchoolPreferences({ni}){
  const { t } = useTranslation('common');
  const params = useMemoCompare({ni}, (prev, next)=>prev && prev.ni===next.ni)
  const [value, {setResult}]= useAPI(retrieveSchoolPreferences, params)
  const handleChange = useCallback(({school, description})=>{
    updateSchoolPreferences({ni, school, description});
    setResult({...value, preferences:value.preferences.map(d=>d.school.slug!==school.slug? d: ({...d, description}))})
  }, [value])

  return <Info.Container modify={true} className="my-8" >
         <InfoSectionHeading mediumTitle={t('school-preferences')}/>
            <SelectPreferences 
          values={value && value.preferences} 
          setValue={handleChange} 
          formatValue={(d)=>d.school.name} 
          options={schoolPreferenceOptions}
          defaultOption={schoolPreferenceOptions.none}/>
        </Info.Container>
}



export function TutorPreferences(){
  const { tutor, setTutor } = useContext(TutorContext);
  const { t } = useTranslation('common');
  const ni = tutor.ni;
  if (!tutor.name){
    return <Skeleton className="h-36 w-full"/>
  }
  return <div className={classnames("space-y-8")}> 
           
            
            <Info.Container modify={true} className="space-y-0" >
         <InfoSectionHeading title={t('preferences')}/>
         <InfoSectionHeading mediumTitle={t('format-preference')}/>
             <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("online-preference")} 
                      modify={true}
                      value={tutor.online_preference || false}
                      setValue={(onlinePreference)=>{updateTutor({ni, onlinePreference}); setTutor(d=>({...d, online_preference: onlinePreference})) }}/>
              <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("hybrid-preference")} 
                      modify={true}
                      value={tutor.hybrid_preference || false}
                      setValue={(hybridPreference)=>{updateTutor({ni, hybridPreference}); setTutor(d=>({...d, hybrid_preference: hybridPreference})) }}/>
              <ModifiableSelectField 
                      Field={CheckBox} 
                      label={t("on-site-preference")} 
                      modify={true}
                      value={tutor.on_site_preference || false}
                      setValue={(onSitePreference)=>{updateTutor({ni, onSitePreference}); setTutor(d=>({...d, on_site_preference: onSitePreference})) }}/>
            </Info.Container>

              <SelectSubjectPreferences ni={ni}/>
              <SelectSchoolPreferences ni={ni}/>

          <Info.Container modify={true} className="my-8" >
         <InfoSectionHeading mediumTitle={t('availabilities')}/>
                 <SelectWeeklyAvailabilities 
              value={tutor.schedule_availabilities}
              setValue={
                (scheduleAvailabilities)=>{
                  updateTutor({ni, scheduleAvailabilities: scheduleAvailabilities.map(e=>[e.from_cron, e.to_cron])});
                  setTutor(d=>({...d, schedule_availabilities: scheduleAvailabilities}))
                }
              }/>
            </Info.Container>



           
         </div>
}

