/*eslint no-unused-vars: "off"*/

import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useQuery } from 'hooks/useQuery';
import { useDebounce } from 'hooks/useDebounce';

// API
import { createComment, updateComment, deleteComment, retrieveNote, deleteCommentTypeOnNote, addCommentTypesOnNote } from 'api/notes';

// Components
import { CardContainer } from 'components/core/container';
import { Button } from 'components/core/button';
import { Error } from 'components/core/typo'
import { EmptyState } from 'components/core/empty';
import { ModifyNote, SetDiscussIcon } from 'components/notes/modify';
import { StateTabs } from 'components/core/tabs';
import { FiUsers, FiMessageCircle, FiGitMerge, FiClipboard, FiClock, FiZap, FiAlertTriangle, FiTrash, FiBookmark, FiCalendar, FiSend,  FiLink } from 'react-icons/fi';
import { Dropdown } from 'components/core/dropdown';
import { Editor } from 'components/core/rich-editor/editor';
import { Viewer } from 'components/core/rich-editor/viewer';
import { SearchCommentTypes } from 'components/tools/search-comment-types';
import { CommentTypeBadge } from 'components/tools/search-comment-types';
import { LinkedComments } from 'components/actions-plan/linkedComments';
import { Button as Btn } from 'components/ui/button';
// Utils
import { locale, parseWithTimeZone, dateParse } from 'utils/locale'
import { recursiveCamelize } from 'utils/casing';
// import { isString } from 'utils/var_check';
// import { InsriptionNoteView } from './views/inscription';
import { TextInput } from 'components/core/inputs';
import { SelectDate } from 'components/tools/select_date';
import { SearchActions } from 'components/actions-plan/searchActions';
import { ActionsPlan } from 'components/actions-plan';
import { Badge } from '../ui/badge';

function CardComment({ id, content, author, minutes, created_at, display_date, onDelete, onChange, codes, linked_comments, student }) {
    const [modifiedComment, setModifiedComment] = useState(content);
    const debouncedComment = useDebounce(modifiedComment, 500);
    const [commentTypes, setCommentTypes] = useState(codes || []);
    const [canModify, setCanModify] = useState();
    const [, setIsSaved] = useState(true);
    const [linkedComments, setLinkedComments] = useState(recursiveCamelize(linked_comments) || []);
    // New comments that are not in default
    const paramsDelete = { commentId: id };
    const [, { execute: executeDelete }] = useAPI(deleteCommentTypeOnNote, paramsDelete, { immediate: false });
    const paramsAdd = { commentId: id};
    const [, { execute: executeAdd }] = useAPI(addCommentTypesOnNote, paramsAdd, { immediate: false });
   
    const [, { error, execute: modifyComment }] = useAPI(updateComment, paramsAdd, { immediate: false })
    const handleRemoveCommentType = (commentType) => {
        setCommentTypes(commentTypes.filter((ct) => ct.code !== commentType.code))
        executeDelete({ codeId: commentType.code });
    }

    const handleChangeCommentTypes = (types) => {
        setCommentTypes(types);
        executeAdd({ codes: types.map((ct) => ct.code) });
    }
    
    const handleSetLinkedComments = (comments) => {
        const toLinkComments = comments?.filter(({id})=>!linkedComments.find(({id: linkedId})=>linkedId===id));
        const toUnlinkComments = linkedComments?.filter(({id})=>!comments.find(({id: linkedId})=>linkedId===id));
        setLinkedComments(comments);
        modifyComment({id, toLinkComments, toUnlinkComments });
    }

    useEffect(() => {
        // Save
        if (canModify && (debouncedComment !== modifiedComment)) {
            setIsSaved(false);
        }
    }, [modifiedComment]);

    useEffect(() => {
        // Save
        if (canModify) {
            modifyComment({ id, comment: debouncedComment });
            setIsSaved(true);
        }
    }, [debouncedComment]);

    const { t } = useTranslation('common');
    return <div className={classnames('!mt-5', canModify ? 'mb-14' : '')}>
        <div className='flex items-center justify-between mb-1'>
            <div className='flex gap-3'>
                <p className="text-sm font-medium text-gray-800 mb">{display_date ?
                    locale.format("Le %A %d %B %Y")(dateParse(display_date)) :
                    locale.format("Le %A %d %B %Y")(parseWithTimeZone(created_at))
                }</p>

            
                <Badge variant="secondary">
                    <>
                        <span >{minutes || '0'}</span>
                        <span className='ml-0.5'>{" " + t("minutes", { count: minutes || 0 })}</span></>
                </Badge>
            </div>
        {!canModify && <Btn size="xs" onClick={() => setCanModify(true)} variant={"ghost"}>{t("edit")}</Btn>}

            
            {/* {!canModify && <Button color="white" size="smToolbarIcon" onClick={() => setCanModify(true)}>
                <FiEdit2 className='!h-3 !w-3 mr-2' /><span className='text-sm'>{t("edit")}</span></Button>} */}
        </div>
        <div className={classnames("relative p-1 overflow-visible flex items-start group rounded-lg w-full", canModify ? "border-2 border-orange-500 " : "bg-gray-100 p-3")}>


            {canModify && <div className='flex items-center mt-0.5 mr-1 gap-2'>

                <Dropdown onlyIcon={<FiBookmark />} tooltip={t("comment-type")}
                    itemClassName="w-[300px]"
                    orientation="left"
                    color={commentTypes && commentTypes.length > 0 ? 'activeColor' : 'default'}
                >
                    <div className='pb-1'>
                        <SearchCommentTypes value={commentTypes} setValue={handleChangeCommentTypes} size="smWider" vOrientation="top" />
                    </div>
                    <div className='flex flex-wrap mb-1'>
                        {
                            commentTypes &&
                            commentTypes.map((commentType, index) => (
                                <CommentTypeBadge key={'selectCmtTypes' + index} className='mt-1 mr-1' removable={true} onRemove={() => handleRemoveCommentType(commentType)} text={commentType.name} color={commentType.color} />
                            ))
                        }
                    </div>

                </Dropdown>

                <Dropdown onlyIcon={<FiClock />}
                    color={minutes ? 'activeColor' : 'default'}
                    tooltip={t("comment-minutes.label")} itemClassName="w-[300px]" orientation="left">
                    <div className="pt-1"><TextInput value={minutes != null ? minutes : ""}
                        size="sm" type="number" color={"gray"} min="0" step="1" xsDescription={t("comment-minutes.label")}
                        onChange={(minutes) => { onChange({ id, minutes }); modifyComment({ id, minutes }) }}
                        placeholder={t("comment-minutes.placeholder")} /></div>
                </Dropdown>

                <Dropdown onlyIcon={<FiCalendar />}
                    color={dateParse(display_date) ? 'activeColor' : 'default'}
                    tooltip={t("Date du commentaire")} itemClassName="w-[300px]" orientation="left">
                    {<div className="pt-1">
                        <SelectDate xsDescription={`Date du commentaire`}
                            value={dateParse(display_date)}
                            setValue={(displayDate) => { onChange({ id, display_date: displayDate && displayDate.toISOString().slice(0, 10) }); modifyComment({ id, displayDate }) }}
                            orientation="right" vOrientation="up" canRemoveSelection={false} /></div>}
                </Dropdown>
                {
                    student && <Dropdown onlyIcon={<FiLink />}
                    color={linkedComments?.length > 0 ? 'activeColor' : 'default'}
                    tooltip={t("linked-actions")}
                    itemClassName="w-[300px] pt-1 pb-0.5"
                    orientation="left"
                    >
                        <SearchActions 
                            value={linkedComments || []} 
                            setValue={handleSetLinkedComments} 
                            multiple 
                            student={student} 
                            placeholder={t("linked-actions")} 
                            optionsClassName={"max-h-[150px]"}
                        />
                    </Dropdown>
                }
            </div>}

            <div className="flex-1" >
                {canModify ?
                    <div className='flex flex-col'>
                        <Editor name="description"
                            minHeight='100px'
                            id="description"
                            placeholder={t("intervention-note-placeholder")}
                            text={modifiedComment || ""}
                            date={dateParse(display_date)}
                            minutes={minutes}
                            setDate={canModify ? (displayDate) => { onChange({ id, display_date: displayDate && displayDate.toISOString().slice(0, 10) }); modifyComment({ id, displayDate }) } : null}
                            setText={setModifiedComment}
                            editorStyles="text-black" />
                        {!canModify && <div className='flex items-center p-2'>
                            <div className='flex flex-wrap w-8/12'>
                                {
                                    commentTypes &&
                                    commentTypes.map((commentType, index) => (
                                        <CommentTypeBadge key={'editCmtTypes' + index} className='mt-2 mr-2' removable={true} onRemove={() => handleRemoveCommentType(commentType)} text={commentType.name} color={commentType.color} />
                                    ))
                                }
                            </div>
                        </div>}
                    </div>
                    :
                    <div>

                        <Viewer className='whitespace-pre-wrap' html={modifiedComment} />
                        {
                            linkedComments?.length > 0 && <div className='border-t py-0.5 mt-1'>
                                <LinkedComments 
                                    student={student} 
                                    parentComment={{id, content, displayDate: display_date}} 
                                    linkedComments={linkedComments} 
                                    isActions
                                    onUnlink={(commentId) => {
                                        return setLinkedComments(prev=>prev.filter(({id})=>id?.toString()!==commentId))
                                    }}
                                />
                            </div>
                        }
                    </div>}
                {canModify &&
                    <div className="flex items-center justify-between px-3 ">
                        <Dropdown onlyIcon={<><FiTrash className='text-red-500 h-4 w-4 mt-0.5 shrink-0' /><span className='ml-2 text-sm text-red-500'>{t("delete")}</span></>} color={"danger"} menuItemsClassName="text-xs !w-56" orientation="left">
                            <Dropdown.Item icon={<FiTrash className='shrink-0' />} name={t("confirm-delete")} color="danger" onClick={() => { onDelete(id); deleteComment({ id }) }} />
                        </Dropdown>
                        <Button color="active" size="xs" onClick={() => setCanModify(false)}>{t('save')}</Button>
                    </div>}
            </div>


        </div>

        <div className="flex items-start justify-between mt-1">

            <div className='flex flex-wrap items-center justify-start'>
                {
                    !canModify && commentTypes && commentTypes.map((code, index) => (
                        <CommentTypeBadge key={'cmtTypes' + index} className='my-0.5 pr-1.5 ml-1' text={code.name} color={code.color} />
                    ))
                }
            </div>
            <div className='flex items-center text-right whitespace-nowrap'>
                <p className="text-sm text-right text-gray-400 ">{locale.format("Modifié le %d %B à %H:%M")(parseWithTimeZone(created_at))}</p>
                {author && <p className="ml-3 text-sm text-gray-800 whitespace-nowrap">{`${author.firstname} ${author.lastname}`}</p>}
            </div>
            {error && <Error.Text {...error} />}
        </div>
    </div>
}

function AddCommentSection({ noteId, student, onResult }) {
    const { t } = useTranslation("common");
    const [closeOnComment,] = useState(false);
    const [content, setContent] = useState("");
    const [minutes, setMinutes] = useState();
    const [commentDisplayDate, setCommentDisplayDate] = useState(new Date());
    const [commentTypes, setCommentTypes] = useState([]);
    const [linkedComments, setLinkedComments] = useState([]);
    const params = useMemo(() => ({ minutes, noteId, closeOnComment, commentDisplayDate, content, linkedComments, codes: commentTypes.map((ct) => ct.code) }), [content, closeOnComment, commentTypes, minutes, commentDisplayDate, linkedComments]);
    const [newComment, { loading, error, execute }] = useAPI(createComment, params, { immediate: false, onResult });

    useEffect(() => {
        if (newComment) {
            setContent("");
            setMinutes(null);
            setCommentTypes([]);
            setLinkedComments([]);
        }
    }, [newComment])

    const handleRemoveCommentType = (commentType) => {
        setCommentTypes(commentTypes.filter((ct) => ct.code !== commentType.code))
    }

    return <>
        <div className=''>
            <div className='flex items-start '>
                {/* Toolbar */}
                <div className='flex items-center mt-0.5 mr-1 gap-2'>

                    <Dropdown onlyIcon={<FiBookmark />} tooltip={t("comment-type")}
                        itemClassName="w-[300px]"
                        orientation="left"
                        color={commentTypes && commentTypes.length > 0 ? 'activeColor' : 'default'}
                    >
                        <div className='pb-1'>
                            <SearchCommentTypes value={commentTypes} setValue={setCommentTypes} size="smWider" vOrientation="top" />
                        </div>
                        <div className='flex flex-wrap mb-1'>
                            {
                                commentTypes &&
                                commentTypes.map((commentType, index) => (
                                    <CommentTypeBadge key={'selectCmtTypes' + index} className='mt-1 mr-1' removable={true} onRemove={() => handleRemoveCommentType(commentType)} text={commentType.name} color={commentType.color} />
                                ))
                            }
                        </div>

                    </Dropdown>

                    <Dropdown onlyIcon={<FiClock />}
                        color={minutes ? 'activeColor' : 'default'}
                        tooltip={t("comment-minutes.label")} itemClassName="w-[300px]" orientation="left">
                        {setMinutes && <div className="pt-1"><TextInput value={minutes != null ? minutes : ""}
                            size="sm" type="number" color={"gray"} min="0" step="1" xsDescription={t("comment-minutes.label")}
                            onChange={e => setMinutes(parseInt(e))}
                            placeholder={t("comment-minutes.placeholder")} /></div>}
                    </Dropdown>

                    <Dropdown onlyIcon={<FiCalendar />}
                        color={commentDisplayDate ? 'activeColor' : 'default'}
                        tooltip={t("Date du commentaire")} itemClassName="w-[300px]" orientation="left">
                        {setCommentDisplayDate && <div className="pt-1"><SelectDate xsDescription={`Date du commentaire`} value={commentDisplayDate} setValue={setCommentDisplayDate} orientation="right" vOrientation="up" canRemoveSelection={false} /></div>}
                    </Dropdown>


                    {
                        student && <Dropdown onlyIcon={<FiLink />}
                        color={linkedComments?.length > 0 ? 'activeColor' : 'default'}
                        tooltip={t("linked-actions")}
                        itemClassName="w-[300px] pt-1 pb-0.5"
                        orientation="left"
                        >
                            <SearchActions value={linkedComments} setValue={setLinkedComments} multiple student={student} placeholder={t("linked-actions")} />
                        </Dropdown>
                    }
                </div>

                <div className='flex items-start flex-1'>
                    <div className='flex-1'><Editor name="comment"
                        id="comment"
                        text={content}
                        setText={setContent}
                        editorStyles="text-black overflow-x-auto" toolbarStyles='' placeholder="Aa" />
                    </div>
                </div>



                {/* <div className='flex flex-col items-end justify-end py-1 pl-2 space-y-2'>
              
            </div> */}


            </div>
            <div className='flex items-center w-full mt-1'>
                <div className='ml-auto'>
                    <Button disabled={!content || content == "<p><br/></p>"/* empty editor value */ || minutes == null}
                        loading={loading} onClick={execute} color="active" size="smToolbarIcon" ><FiSend /> <span className='ml-2 text-sm'>{t(closeOnComment ? "add-comment-and-close" : "add-comment")}</span>  </Button>
                </div>
            </div>
            {error ? <Error.Text {...error} /> : null}
            {/* <div className={classnames("bg-white flex-1 py-3 space-y-2")}>
    {noteIsOpen && <CheckBox value={closeOnComment} setValue={setCloseOnComment} name={t("close-on-comment")} orientation="left" />}

      
    </div> */}
        </div>
    </>


}

export function Comments({ note, setNote }) {
    const { t } = useTranslation("common");
    const handleDeleteComment = useCallback((commentId) => {
        setNote(d => ({
            ...d,
            comments: d.comments.filter(e => e.id !== commentId)
        }))
    }, [])

    const onChange = (value) => setNote((note) => ({ ...note, comments: note.comments.map(d => d.id === value.id ? ({ ...d, ...value }) : d) }))

    return <CardContainer >
        {note.comments.length === 0 ?
            <EmptyState title={t("empty-state.no-comments-title")} />
            :
            <div className="flex flex-col-reverse pt-3 space-y-5 overflow-y-auto h-[calc(100vh-490px)] pb-10 pr-2 z-50">
                {note.comments.map((d) => {
                    return <CardComment key={d.id} {...d} student={note?.student} onDelete={handleDeleteComment} onChange={onChange} />
                })}
            </div>
        }
        <AddCommentSection student={note?.student} noteId={note.id} onResult={setNote} noteIsOpen={!note.closed_at} />
    </CardContainer>
}
function AlertNoteIsOpen() {
    const { t } = useTranslation("common");
    return <p className={classnames("text-yellow-600 bg-yellow-100 rounded-md p-3 text-sm hidden")}>
        <FiAlertTriangle className="inline mr-2" />{t("note-is-still-open")}
    </p>

}

function Header({ note, setNote }) {
    const { t } = useTranslation("common");
    return <div className="w-full mx-auto -mt-9 ">
        <div className="flex items-center">
            <div className="flex items-center">
                <div className="flex-shrink-0 w-6 h-6 mr-2 rounded-lg" style={{ background: note.code.color }} />
                <div className='flex justify-between'>
                    <div className=" text-2xl font-medium text-gray-900 max-w-[75%] ">{note.code.name}</div>
                    <div className='mr-12'>
                        <SetDiscussIcon note={note} setNote={setNote} />
                    </div>
                </div>

            </div>


        </div>
        <div className="px-3 space-y-0 sm:space-y-3 ">
            {note.student &&
                <div className="inline-flex items-center mr-3 space-x-1 whitespace-nowrap">
                    <FiUsers />
                    <Button color="inlineLink" href={`/students/${note.student.ni}`}>{note.student.name}</Button>
                </div>}

            {note.tutor &&
                <div className="inline-flex items-center mr-3 space-x-1 whitespace-nowrap">
                    <FiZap />
                    <Button color="inlineLink" block href={`/tutors/${note.tutor.ni}`}>{note.tutor.name}</Button>
                </div>}

            {note.pairing &&
                <div className="inline-flex items-center mr-3 space-x-1 whitespace-nowrap">
                    <FiGitMerge />
                    <Button color="inlineLink" block href={`/pairings/${note.pairing.id}`}>{t("see-pairing")}</Button>
                </div>
            }
            {note.document && note.document.group && note.document.period &&
                <div className="inline-flex items-center mr-3 space-x-1 whitespace-nowrap">
                    <span className="flex-shrink-0 inline-block w-4 h-4 ml-2 rounded-md " style={{ background: note.document.group.color }} />
                    <p className=''>{`${note.document.group.name} ${note.document.period.name}`}</p>
                </div>
            }

            {note.coach &&
                <div className="inline-flex items-center mr-3 space-x-1 whitespace-nowrap">
                    <FiClipboard />
                    <Button color="inlineLink" block href={`/coachs/${note.coach.ni}`}>{note.coach.name}</Button>
                </div>}


            {note.should_discuss && <div className="inline-flex items-center mr-3 space-x-1 text-pink-500 whitespace-nowrap">
                <FiMessageCircle />
                <p> {locale.format("%d %B %Y")(parseWithTimeZone(note.should_discuss))}</p>
            </div>}
        </div>

        <div className="inline-flex items-center px-2 py-1 space-x-2 text-sm bg-gray-100 rounded-md">
            <FiClock className='' />
            <p>{note.closed_at ? t("closed-at-time", { time: locale.format("%d %B %Y")(dateParse(note.closed_at.slice(0, 10))) }) :
                note.opened_at ? t("open-at", { time: locale.format("%d %B %Y")(dateParse(note.opened_at.slice(0, 10))) }) :
                    t("not-open-at")}</p>
        </div>
    </div>
}
/*
  The navigation in the note extended view is search based or navigation...?
*/
export function NoteExtendedView({ note, setNote, onDelete }) {
    const { t } = useTranslation("common");
    const {student} = note;
    
    const tabs = useMemo(() => {
        const baseTabs = [
            {
                name: t("Commentaires"),
                val: "comments"
            },
            {
                name: t("Paramètres"),
                val: "settings"
            }
        ];

        if (student) {
            baseTabs.splice(1, 0, {
                name: t("Plan d'actions"),
                val: "actions-plan"
            });
        }

        return baseTabs;
    }, [student, t]);
    const [tab, setTab] = useState(tabs[0]);

    if (!note) return <div />;

    return <div className="flex flex-col flex-1">
        <CardContainer className="sm:px-0">
            <Header note={note} setNote={setNote} />
        </CardContainer>
        {!note.closed_at && <CardContainer className="mb-2"><AlertNoteIsOpen note={note} setNote={setNote} /></CardContainer>}
        <div className='px-3 md:px-0'><StateTabs tabs={tabs} value={tab} setValue={setTab} /></div>
        {tab.val === "actions-plan" && student && <div className='p-4'><ActionsPlan student={student} hideStats/></div>}
        {tab.val === "comments" && <Comments note={note} setNote={setNote} />}
        {tab.val === "settings" && <CardContainer><ModifyNote onDelete={onDelete} note={note} setNote={setNote} /></CardContainer>}
        {/*<Tabs/>*/}
    </div>
}

/*
  Autonomous card based on query params (noteId).
*/
export function NoteExtendedViewQueryBased({ onDelete }) {
    const { query } = useQuery();
    const noteId = useMemoCompare(query, (prev, next) => prev && (prev.get("noteId") !== next.get("noteId")));
    const params = useMemo(() => ({ noteId: query.get("noteId"), linkedComments: true }), [noteId]);
    const [note, { setResult }] = useAPI(retrieveNote, params, { immediate: true, validateParams: (p) => p.noteId });

    if (!note) return <div />;
    // const code = isString(note.code)? note.code: (note.code && note.code.code) || note.code;
    // if (code==="commentaires-dinscription") return <InsriptionNoteView note={note} setNote={setResult} onDelete={onDelete} />
    return <NoteExtendedView note={note} setNote={setResult} onDelete={onDelete} />
}