import React from 'react';

// Components
import { FiCalendar } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';
import { AADDatesList } from 'components/aad-dates/list';

function SettingsAADDates(){
  const {t} = useTranslation("common");
  
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('aad-exclusion-dates')}</h1>
            <p className='mb-3 text-base text-gray-500'>{t('aad-exclusion-dates-description')}</p>
            <AADDatesList/>
          </div>
}
const route =  { path: "aad-dates", 
                 name: "Exclusion aides aux devoirs",
                 group: "data",
                 Icon: FiCalendar,
                 requireAuth: true, 
                 scopes: ["Direction", "RST", "Responsable", "Développeur"],
                 Element: SettingsAADDates };
export default route;
