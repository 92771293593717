import {timeMonth} from 'd3-time';

export function getCurrentPeriod(){
  const date = timeMonth.offset(new Date(), -6); // 1er juillet
  return `${date.getFullYear()}-${date.getFullYear()+1}`
}

export function getFullCurrentPeriod(){
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-11, où 0 = janvier, 6 = juillet
    
    // Si nous sommes entre janvier (0) et juin (5), l'année de début est l'année précédente
    // Sinon (juillet à décembre), l'année de début est l'année en cours
    const startYear = currentMonth <= 5 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    
    const start = new Date(startYear, 6, 1); // 1er juillet de l'année de début
    const end = new Date(startYear + 1, 5, 30); // 30 juin de l'année suivante
    
    return {slug: getCurrentPeriod(), name: getCurrentPeriod(), start, end};
} 